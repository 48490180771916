@font-face {
  font-family: 'Corbel';
  src: local('Corbel'), url('./fonts/CORBELB.TTF') format('truetype');
}

a {
  &:hover {
    color: #ae8a1e99 !important;
  }
}

.logo {
  width: 50%;
  background: #faf4e2;
}

.App {
  text-align: center;
}

.footer-divider {
  border-top: 3px solid #fab31b
}

.about-us {
  font-family: 'Corbel';
  font-size: 18px;
  font-weight: 900;
  background: #faf4e2;
  width: 60%;
  max-width: 720px;
}

.contact-us {
  font-family: 'Corbel';
  font-weight: 550;
  background: #faf4e2;
  width: 80%;
  max-width: 900px;
}

.title {
  font-family: 'Corbel';
  font-weight: 700;
  padding: 0px;
}

.content {
  font-family: 'Corbel';
  font-weight: 500;
  margin-bottom: 5px;
  color: #ae8a1e;
}

.box {
  // border: 2px solid #faf4e2 !important;
  // border-radius: 20px;
}

.custom-padding {
  padding: 3px !important;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.query {
  width: 80%;
  margin-top: 15px;
}

.query input,
.query textarea,
.query input:focus,
.query textarea:focus,
.query input:hover,
.query textarea:hover {
  border: 2px solid #cba122;
  border-radius: 10px;
  background: #fffd;
  margin: 10px auto;
  padding: 10px;
  display: block;
  outline: none;
  transition: 0.25s;
}

.query button,
.query button:active {
  // border: 2px solid #cba122;
  border: 0;
  border-radius: 24px;
  background: #cba12299;
  margin: 10px auto;
  padding: 10 40px;
  display: block;
  outline: none;
  transition: 0.25s;

  &:hover {
    background: #ae8a1e;
  }
}

.sm a {
  display: inline-block;
  font-size: 20px;
  text-align: center;
  color: #ae8a1e;
  margin: 0 10px;
}

.custom-nav {
  &:hover {
    // background: url(assets/button.jpg);
    // background-repeat: round;
    // background-size: contain;
    background-color: #e7e1ce;
    border-radius: 6px;
    font-family: 'Corbel';
    font-weight: 700;
    padding: 1px 5px;
  }

  // background: url(assets/button.jpg);
  // background-repeat: round;
  background-color: #e7e1ce;
  border-radius: 6px;
  font-family: 'Corbel';
  font-weight: 700;
  padding: 1px 5px;
  background-size: contain;
}

.custom-nav-hover {
  &:hover {
    // background: url(assets/button.jpg);
    // background-repeat: round;
    // background-size: contain;
    background-color: #e7e1ce;
    border-radius: 6px;
    padding: 1px 5px;
  }

  font-family: 'Corbel';
  font-weight: 700;
}

.site-layout-sub-header-background {
  background: #faf4e2;
}

.site-layout-background {
  background: #faf4e2;
  display: flex;
}

.menu-li {
  padding: 0 !important;
  text-align: center;
}

.box span {
  display: none;
  font-size: 35px;
  color: #444 !important;
  font-family: sans-serif;
  text-align: center;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 50px;
  cursor: pointer;
  border-bottom: ;
  /*text-decoration: none;*/
}

.parent:hover span,
.parent:focus span {
  display: block;
}

.child:before {
  content: "";
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
}

.parent {
  overflow: hidden;
  position: relative;
  display: inline-block;
  cursor: pointer;
  // border: 3px solid #faf4e2 !important;
  border-radius: 20px;
}

.inner {
  // border: 3px solid #faf4e2;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.child {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.parent:hover .child,
.parent:focus .child {
  -ms-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}

.parent:hover .child:before,
.parent:focus .child:before {
  display: block;
}

.cmt-3 {
  margin-top: 6px;
}

// XS & SM screen size
@media screen and (max-width: 767px) {
  .home {
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-top: 50px;
  }

  .about-us,
  .contact-us {
    height: fit-content;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    margin: auto;
  }

  .video {
    width: 320px !important;
    height: 180px !important;
  }

  .article1 {
    background: url("./assets/1.jpg");
    height: 40vh;
  }

  .article2 {
    background: url("./assets/2.jpg");
    height: 40vh;
  }

  .article3 {
    background: url("./assets/3.jpg");
    height: 40vh;
  }

  .article4 {
    background: url("./assets/4.jpg");
    height: 40vh;
  }

  .article5 {
    background: url("./assets/5.jpg");
    height: 40vh;
  }
  .article6 {
    background: url("./assets/6.jpg");
    height: 40vh;
  }

  .article7 {
    background: url("./assets/7.jpg");
    height: 40vh;
  }

  .article8 {
    background: url("./assets/8.jpg");
    height: 40vh;
  }

  .article9 {
    background: url("./assets/9.jpg");
    height: 40vh;
  }

  .article10 {
    background: url("./assets/10.jpg");
    height: 40vh;
  }
}

// MD & LG screen size
@media screen and (min-width:768px) and (max-width: 1199px) {
  .main-area {
    margin-left: 200px;
  }

  .about-us,
  .contact-us {
    height: fit-content;
    top: 0;
    bottom: 0;
    left: 200px;
    right: 0;
    position: absolute;
    margin: auto;
  }

  .contact-us {
    max-width: 550px;
  }

  .home {
    padding-right: 20px !important;
    padding-top: 80px;
  }

  .video {
    width: 720px !important;
    height: 405px !important;
  }

  .cust-height {
    height: 60vh;
  }

  .article1 {
    background: url("./assets/1.jpg");
    height: 60vh;
  }

  .article2 {
    background: url("./assets/2.jpg");
    height: 60vh;
  }

  .article3 {
    background: url("./assets/3.jpg");
    height: 60vh;
  }

  .article4 {
    background: url("./assets/4.jpg");
    max-height: 30vh;
  }

  .article5 {
    background: url("./assets/5.jpg");
    max-height: 30vh;
  }

  .article6 {
    background: url("./assets/6.jpg");
    height: 60vh;
  }

  .article7 {
    background: url("./assets/7.jpg");
    height: 60vh;
  }

  .article8 {
    background: url("./assets/8.jpg");
    height: 60vh;
  }

  .article9 {
    background: url("./assets/9.jpg");
    max-height: 30vh;
  }

  .article10 {
    background: url("./assets/10.jpg");
    max-height: 30vh;
  }

}

// XL screen size
@media screen and (min-width: 1200px) {
  .main-area {
    margin-left: 200px;
  }

  .about-us,
  .contact-us {
    height: fit-content;
    top: 0;
    bottom: 0;
    left: 200px;
    right: 0;
    position: absolute;
    margin: auto;
  }

  .home {
    padding-right: 20px !important;
    padding-top: 80px;
  }

  .video {
    width: 960px !important;
    height: 540px !important;
  }

  .side-border {
    border-top: 1.5px solid #faf4e2 !important;
    border-bottom: 1.5px solid #faf4e2 !important;
    border-left: 3px solid #faf4e2 !important;
    border-right: 3px solid #faf4e2 !important;
  }

  .cust-height {
    height: 60vh;
  }

  .article1 {
    background: url("./assets/1.jpg");
    height: 60vh;
  }

  .article2 {
    background: url("./assets/2.jpg");
    height: 60vh;
  }

  .article3 {
    background: url("./assets/3.jpg");
    height: 60vh;
  }

  .article4 {
    background: url("./assets/4.jpg");
    max-height: 30vh;
  }

  .article5 {
    background: url("./assets/5.jpg");
    max-height: 30vh;
  }

  .article6 {
    background: url("./assets/6.jpg");
    height: 60vh;
  }

  .article7 {
    background: url("./assets/7.jpg");
    height: 60vh;
  }

  .article8 {
    background: url("./assets/8.jpg");
    height: 60vh;
  }

  .article9 {
    background: url("./assets/9.jpg");
    max-height: 30vh;
  }

  .article10 {
    background: url("./assets/10.jpg");
    max-height: 30vh;
  }
}
@primary-color: #faf4e2;@menu-item-color: #555555;@menu-highlight-color: #555555;@menu-bg: @primary-color;@menu-item-active-bg: @primary-color;@layout-header-background: @primary-color;