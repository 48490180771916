.ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after {
    transform: scaleY(0);
    opacity: 0!important;
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: 22px !important;
    height: 22px !important;
}

.inline .ant-menu-item:not(:last-child) {
    margin-bottom: 8px;
}

.sticky-top {
    position: fixed !important;
    z-index: 1 !important;
}

.anticon {
    color: #ffbf00 !important;
}

.ant-layout-sider-zero-width-trigger{
    background: #b25153 !important;
}

.ant-modal-close {
    display: none !important;
}

.ant-modal-body {
    padding: 0 !important;
}